import React from "react"

import styles from "./colorDotsStyles.module.scss"

const ColorDots = ({ style }) => {
  return (
    <div className={styles.wrapper} style={{ ...style }}>
      <div className={[styles.colorDot, styles.red].join(" ")}></div>
      <div className={[styles.colorDot, styles.blue].join(" ")}></div>
      <div className={[styles.colorDot, styles.yellow].join(" ")}></div>
    </div>
  )
}

export default ColorDots
