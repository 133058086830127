import React from "react"

import styles from "./textInputStyles.module.scss"

const TextInput = ({
  value,
  onChange,
  className,
  style,
  fluid,
  placeholder,
  leftAlign,
}) => {
  return (
    <input
      type="email"
      className={[styles.input, className, "serif"].join(" ")}
      value={value}
      onChange={e => {
        onChange(e.currentTarget.value)
      }}
      placeholder={placeholder}
      style={{
        ...style,
        display: fluid ? "block" : "inline-block",
        width: fluid ? "100%" : null,
        textAlign: leftAlign ? "left" : "center",
      }}
    ></input>
  )
}

export default TextInput
