import React from "react"

import styles from "./containerStyles.module.scss"

const Container = ({ style, children, className, thin }) => {
  return (
    <div
      className={[styles.wrapper, className, thin ? styles.thin : null].join(
        " "
      )}
      style={{ ...style }}
    >
      {children}
    </div>
  )
}

export default Container
