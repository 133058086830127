import React from "react"
import PersonalizedTherapy from "../imageComponents/personalizedTherapy"
import styles from "./footerStyles.module.scss"
import Link from "../Link/Link"
import facebook from "../../images/facebook.svg"
import ig from "../../images/instagram.svg"
import HandWithBud from "../imageComponents/handWithBud"
import Container from "../Container/Container"
import pctLogo from "../../images/kindbio-pct.svg"

const FooterItem = ({ children, header }) => {
  return (
    <div className={styles.item}>
      <h1 className={styles.header1}>{header}</h1>
      {children}
    </div>
  )
}

const Footer = props => {
  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.textWrapper}>
          <FooterItem header="Partner With Us">
            <Link
              to="mailto:hello@kindbio.com"
              style={{ marginBottom: "20px", display: "block" }}
            >
              Product Sales
            </Link>
            <Link
              to="mailto:wholesale@kindbio.com"
              style={{ display: "block" }}
            >
              Manufacturing
            </Link>
          </FooterItem>

          <FooterItem header="Contact">
            <Link to="mailto:hello@kindbio.com">hello@kindbio.com</Link>
          </FooterItem>

          <FooterItem header="Say Hi">
            <div>
              <a
                href="https://www.instagram.com/kind_bio/?hl=en"
                target="_blank"
              >
                <img
                  src={ig}
                  alt="Instagram Logo"
                  style={{ marginRight: "37px" }}
                ></img>
              </a>
              <a href="https://www.facebook.com/KindBioCanna" target="_blank">
                <img src={facebook} alt="Facebook Logo"></img>
              </a>
            </div>
          </FooterItem>
        </div>
        <div className={styles.logoWrapper}>
          <Link to="/">
            <PersonalizedTherapy />
          </Link>
        </div>
        <div>
          <div className={styles.emptyBox}></div>
        </div>
        <div className={styles.handWithBudWrapper}>
          <HandWithBud></HandWithBud>
        </div>
        <div className={styles.finePrint}>
          <Link
            style={{ marginRight: "20px" }}
            to="/privacy-policy"
            reverseUnderline
          >
            PRIVACY POLICY
          </Link>

          <span>© 2020 KindBio LLC</span>
        </div>
      </div>
    </Container>
  )
}

export default Footer
