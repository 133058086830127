import React from "react"
import styles from "./spinnerStyles.module.scss"
const Spinner = ({ style, className }) => {
  return (
    <div
      className={[styles.spinner, styles.className].join(" ")}
      style={{ margin: "0 auto", ...style }}
    ></div>
  )
}

export default Spinner
