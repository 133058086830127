import React, { Fragment, useState } from "react"
import Container from "../Container/Container"
import ColorDots from "../ColorDots/ColorDots"
import TextInput from "../TextInput/TextInput"
import Button from "../Button/Button"

import styles from "./emailStyles.module.scss"
import Spinner from "../Spinner/Spinner"
import { validateEmail } from "../../utils/helpers"

const EmailSignup = props => {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [showInvalidMsg, setShowInvalidMsg] = useState(false)
  const [isSignedUp, setIsSignedUp] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    if (validateEmail(email)) {
      setIsLoading(true)
      fetch(
        "https://api-dot-kindbio-605c7.uc.r.appspot.com/api/addToMailingList",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: email }),
        }
      )
        .then(response => {
          if (response.status === 200) {
            setIsLoading(false)
            setIsSignedUp(true)
          }
          return response.json()
        })
        .then(jsonResponse => {
          console.log(jsonResponse)
          if (jsonResponse.code === 11000) {
            setApiError(
              "Thanks, you have already signed up for our mailing list!"
            )
          } else {
            setApiError("Server Error. Please try again later.")
          }
          setIsLoading(false)
        })
    } else {
      setShowInvalidMsg(true)
    }
  }

  return (
    <Container style={{ marginTop: "200px" }}>
      <div className={styles.contactContainer}>
        <ColorDots></ColorDots>
        <h1 style={{ marginTop: "20px" }}>Stay Informed</h1>
        <p className="mt3">
          Discover your KindBio True Strain, and identify the personalized
          therapy to best suit your needs. Come grow with us.
        </p>
        {isSignedUp ? (
          <div style={{ textAlign: "center" }}>
            Thanks! You're on our mailing list.
          </div>
        ) : isLoading ? (
          <Spinner></Spinner>
        ) : apiError ? (
          <div>{apiError}</div>
        ) : (
          <Fragment>
            <form
              className={[styles.formWrapper, "mt3"].join(" ")}
              onSubmit={handleSubmit}
            >
              <TextInput
                value={email}
                onChange={value => {
                  if (showInvalidMsg) {
                    setShowInvalidMsg(false)
                  }
                  setEmail(value)
                }}
                placeholder="email address"
                style={{ margin: "10px" }}
              ></TextInput>
              <Button style={{ margin: "10px" }} type="submit">
                SUBMIT
              </Button>
            </form>
            {showInvalidMsg ? (
              <div style={{ color: "red" }}>
                Please enter a valid email address.
              </div>
            ) : null}
          </Fragment>
        )}
      </div>
    </Container>
  )
}

export default EmailSignup
